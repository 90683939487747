<template>
    <CotizacionesComponent />
</template>

<script>
import CotizacionesComponent from "../components/CotizacionesComponent";
import { api as projectApi } from "../../projects/services";
import { mapState } from "vuex";

export default {
  data() {
    return {
      project: null,
    };
  },
  computed: {
    ...mapState("auth", ["user"])
  },
  components: {
    CotizacionesComponent,
  },
  async mounted() {
    const projectId = this.$route.params.id;
    this.project = await projectApi.get(projectId);
    this.$store.state.project.breadcrumbs = [
      {
        pageName: 'Cotizaciones',
        route: '/cotizaciones',
      },
      {
        pageName: `${ this.project ? this.project.name  : ''}`,
        route: `/reservas-en-proyecto/${this.$route.params.id}`,
      },
    ];
  }
};
</script>

<style lang="scss" scoped></style>
