<template>
  <div v-show="true" id="data-list-thumb-view" class="data-list-container">
    <div v-if="showPendingQuotes" class="p-2">
      <QuotesList />
    </div>
    <div v-if="showAprovedQuotes" class="p-2">
      <Aceptadas />
    </div>
    <div v-if="showCanceledQuotes" class="p-2">
      <DenegadasComponet />
    </div>
  </div>
</template>

<script>
import Aceptadas from "./Aceptadas";
import DenegadasComponet from "./DenegadasComponet";
import QuotesList from "./List"

export default {
  name: "ListadoProyectos",
  data() {
    return {
      colorx: "primary",
      showPendingQuotes: true,
      showAprovedQuotes: false,
      showCanceledQuotes: false,
      getQuoteDate: [],
      getQuoteExpires: [],
      getQuoteGreen: [],
      getQuoteDateRange: [],
    };
  },
  components: {
    Aceptadas,
    DenegadasComponet,
    QuotesList
  },
};
</script>
