<template>
  <div>
    <vx-card title="📃📁 Cotizaciones emitidas" title-color="black">
      <vs-row w="12">
        <vs-col vs-w="8">
          <p class="mb-1">
            En esta sección encontrara todas las cotizaciones generadas.
            <br />Puede generar una
            {{ $tc("message.reservation", 1, user.countryCode) }} sobre una
            cotización activa como tambien archivos pdf para el cliente final.
          </p>
        </vs-col>
        <vs-col vs-w="4">
          <h5 style="text-align: right; color: #626262">
            Total de registros:
            <span> {{ rowsCount }}</span>
          </h5>
        </vs-col>
      </vs-row>
    </vx-card>
    <br />
    <DataTable
      :headers="headers"
      :itemsData="quotes"
      :itemPerPage="itemsPerPage"
      :totalItems="rowsCount"
      @serverSideMethod="getQuotes"
      :load="loadingTable"
      :showSelect="true"
      sortBy="created_at"
      :sortAsc="false"
    >
    </DataTable>
    <vs-popup title="Eliminar" :active.sync="popupDeleteQuote">
      <vs-row>
        <h5>
          Se eliminará cotización y
          {{ $tc("message.reservation", 1, user.countryCode) }}. ¿Deseas
          eliminar?
        </h5>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <vs-button
            color="danger"
            @click.native="deleteQuote(selectedQuoteToDelete)"
            class="mt-2"
            icon-pack="feather"
            icon="icon-trash"
            >Sí, eliminar</vs-button
          >
        </vs-col>
      </vs-row>
    </vs-popup>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { quotesApi } from "../services";
import dayjs from "../../../helpers/days";
import DataTable from "../../../components/DataTable.vue";
import { sentryCaptureException } from "../../../helpers/Sentry";

export default {
  name: "QuotesList",
  components: {
    DataTable,
  },
  computed: {
    ...mapState("auth", ["user"]),
  },
  data() {
    return {
      quotes: [],
      rowsCount: 0,
      itemsPerPage: 10,
      headers: [],
      popupDeleteQuote: false,
      selectedQuoteToDelete: null,
      loadingTable: true,
      sellerId: null
    };
  },
  async beforeMount() {
    this.headers = [
      {
        text: "Unidad",
        value: "quote_details.property.unit_number",
        width: 130,
        align: "left",
        cellRenderer: "composed",
        cellRendererParams: (params) =>
          this.getProperty(params.quote_details)
            ? this.getProperty(params.quote_details).unit_number
            : "-",
        filterValue: { type: null, value: "" },
        filter: true,
      },
      {
        text: "Vendedor",
        value: "user.first_name",
        align: "left",
        cellRenderer: "composed",
        width: 180,
        hide: (this.user.role === "ADMIN" || this.user.role === "SUPERVISOR") ? false : true,
        cellRendererParams: (params) => this.getSellerFullname(params.user),
        filterValue: { type: null, value: "" },
        filter: true,
      },
      {
        text: "Cliente",
        value: "client.first_name",
        width: 180,
        align: "left",
        cellRenderer: "composed",
        cellRendererParams: (params) => {
          return params.client.last_name
            ? `${params.client.first_name}  ${params.client.last_name}`
            : params.client.first_name;
        },
        filterValue: { type: null, value: "" },
        filter: true,
      },
      {
        text: "Correo electrónico",
        value: "client.email",
        width: 225,
        align: "left",
        cellRenderer: "composed",
        cellRendererParams: (params) => params.client.email,
        filterValue: { type: null, value: "" },
        filter: true,
      },
      {
        text: "Fecha de Creación",
        value: "created_at",
        width: 200,
        align: "left",
        openCalendar: false,
        cellRenderer: "composed",
        cellRendererParams: (params) =>
          dayjs(params.created_at).format("DD/MM/YY"),
        filterType: "Date",
        filterValue: { type: null, value: null },
        filter: true,
      },
      {
        text: "Estado",
        value: "status",
        width: 180,
        align: "left",
        cellRenderer: "flat",
        filterType: "Estado",
        filterQuotes: true,
        filterValue: { type: null, value: "" },
        filter: true,
        filterStatus: [
          { name: "Abierto", value: "OPEN" },
          { name: "Perdido", value: "LOST" },
          { name: "Expirado", value: "EXPIRED" },
          { name: "Reservado", value: "RESERVED" },
          { name: "Opcionado", value: "OPTIONED" },
        ],
        cellRendererParams: (item) => this.getStatus(item),
      },
      {
        text: "Acciones",
        value: "actions",
        align: "left",
        cellRenderer: "actions",
        cellRendererParams: (params) => {
          const buttons = [
            {
              color: "#1973E8",
              icon: "icon-eye",
              title: "ver",
              event: () => {
                this.showQuote(params);
              },
            },
          ];

          if (params.quote_status.id !== "RESERVED") {
            buttons.push({
              color: "#F24646",
              icon: "icon-trash",
              title: "Eliminar",
              event: () => {
                this.delete(params);
              },
            });
          }
          return buttons;
        },
        sortable: false,
      },
    ];
  },
  async mounted() {
    try {
      const { userId, role } = this.$store.getters["auth/getUser"];
      const sellerId = (role !== "ADMIN" && role !== "SUPERVISOR") ? userId : null;

      this.sellerId = sellerId;

      this.rowsCount = await quotesApi.totalQuotesList(
        this.$route.params.id,
        sellerId
      );

    } catch (e) {
      sentryCaptureException(e, "quote");
      return;
    }
  },
  methods: {
    async getQuotes(options) {
      this.loadingTable = true;
      const { ordering, pagination, filtering } = options;

      this.quotes = await quotesApi.GetQuotes(
        this.$route.params.id,
        ordering,
        pagination,
        filtering,
        this.sellerId
      );

      this.loadingTable = false;
    },
    parseDate(str) {
      return dayjs(str).format("DD/MM/YYYY");
    },
    getProperty(properties) {
      const propertyType = properties.filter((item) => {
        if (item.property) {
          const type = item.property.property_type_id;
          return type !== "PARKING" && type !== "WAREHOUSE";
        }
      });
      if (!propertyType.length) {
        return null;
      }
      return propertyType[0].property;
    },
    getStatus(data) {
      return {
        color: data.quote_status
          ? this.statusColor(data.quote_status.id)
          : null,
        value: data.quote_status ? data.quote_status.name : null,
      };
    },
    statusColor(status) {
      if (status == "OPEN") return "green";
      if (status == "EXPIRED" || status == "LOST") return "red";
      if (status == "RESERVED" || status == "OPTIONED") return "dark";
    },
    showQuote(data) {
      this.$router.push({
        name: "Cotización",
        params: {
          id: data.id,
        },
      });
    },
    delete(data) {
      this.popupDeleteQuote = true;
      this.selectedQuoteToDelete = data;
    },
    getSellerFullname(seller) {
      if (!seller.last_name) {
        return seller.first_name;
      }

      return `${seller.first_name} ${seller.last_name}`;
    },
    async deleteQuote(quote) {
      try {
        await quotesApi.deleteById(quote.id);

        this.popupDeleteQuote = false;
        this.selectedQuoteToDelete = null;

        this.$vs.notify({
          time: 6000,
          title: `Cotización ${quote.id} 🏢🚀.`,
          text: `Eliminada correctamente.`,
          color: "success",
        });
      } catch (e) {
        this.$vs.notify({
          title: `Error`,
          text: `No ha sido posible eliminar la cotización`,
          color: "danger",
        });
      }
      await this.getQuotes({
        ordering: "order_by: {created_at: desc}",
        pagination: "limit: 10, offset: 0",
        filtering: ""
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.header-cell {
  background-color: #a6e1ec;
  font-size: 25px;
  font-weight: bold;
  text-align: center;
}
.quarters-cell {
  background-color: #5bc0de;
  font-weight: bold;
}
</style>
